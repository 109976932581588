.overlay{
    width: 100%;
    height: 700px;
    background-image: url('../../assets/home-banner.jpg');
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    backdrop-filter: brightness(30%) saturate(100%);
}
.overlay-filter{
    height: 100%;
    backdrop-filter: brightness(40%) saturate(100%);
    display: flex;
    align-items: center;
    justify-content: end;
    overflow: hidden;
}
.secondary-btn{
    background-color: var(--orange);
    padding: 12px 30px;
    color: white;
    border: 0px;
    border-radius: 5px;
    font-size: 16px;
    max-width: 176px;
    cursor: pointer;
    transition: 0.3s;
    margin-top: 10px;
}
.secondary-btn:hover{
    transform: translateY(-10px);
}
.overlay-content{
    color: white;
    display: flex;
    flex-direction: column;
    align-items: center;
    row-gap: 16px;
}
.overlay-descriere{
    max-width: 560px;
    line-height: 24px;
    text-align: center;
}
.overlay-title{
    color: var(--orange);
    font-size: 60px;
}
.overlay-subtitle{
    font-size: 40px;
    font-weight: 700;
    max-width: 700px;
    text-align: end;
}
.overlay-services{
    font-size: 32px;
    font-weight: 700;
    max-width: 700px;
    text-align: end;
    text-align: center;
}
.acasa-servicii{
    display: flex;
    flex-direction: column;
    align-items: center;
    row-gap: 16px;
    justify-content: center;
    padding: 120px 0px;
}
.acasa-servicii p:nth-child(1){
    color: var(--orange);
}
.acasa-servicii > h2{
    font-size: 40px;
}
.card-servicii{
    max-width: 250px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    row-gap: 12px;
}
.card-servicii img{
    max-width: 70px;
}
.card-servicii p {
    text-align: center;
    line-height: 24px;
}
.card-servicii h2 {
    text-align: center;
    line-height: 24px;
}
.lista-servicii{
    padding-top: 80px;
    padding-bottom: 80px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.portofoliu-div{
    display: flex;
    flex-direction: column;
    row-gap: 14px;
}
.portofoliu-div p {
    font-size: 24px;
    color: var(--orange);
}
.portofoliu-poze{
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
    gap: 10px;
    margin-top: 24px;
    margin-bottom: 100px;
}
.img{
    width: 100%;
    height: 100%;
    max-height: 350px;
    object-fit: cover;
}
.bg{
    height: 300px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    row-gap: 8px;
}
.bg h2{
    text-align: center;
    font-size: 32px;
}
.bg p:nth-child(1){
    color: var(--orange);
    font-size: 24px;
}
.blue-filter{
    filter: brightness(0) saturate(100%) invert(47%) sepia(96%) saturate(383%) hue-rotate(146deg) brightness(92%) contrast(96%);
}
@media (max-width:768px) {
    .lista-servicii{
        flex-direction: column;
        row-gap: 40px;
        padding-top: 24px;
        padding-bottom: 24px;
    }
    .acasa-servicii{
        padding: 48px 0px;
    }
    .portofoliu-poze{
        margin: 24px 0px;
    }
}
@media (max-width:1024px) {
   .overlay{
    height: 500px;
   }
   .overlay-filter{
    justify-content: start;
    backdrop-filter: brightness(25%) ;
   }
   .overlay-content{
    align-items: start;
   }
   .overlay-subtitle{
    text-align: start;
   }
   .overlay-descriere{
    text-align: start;
   }
   .overlay-services{
    text-align: start;
    font-size: 24px;
   }
}
@media (max-width:425px) {
    .overlay-title{
        font-size: 32px;
    }
    .overlay-subtitle{
        font-size: 32px;
    }
}