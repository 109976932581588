.despre-div{
    padding-bottom: 80px;
}
.servicii-top-despre{
    width: 100%;
    height: 200px;
    background-color: var(--orange);
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
}
.servicii-top-despre h2{
    font-size: 36px;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
}
.despre-title{
    padding-top: 40px ;
    padding-bottom: 40px ;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    row-gap: 24px;
}
.despre-title h1{
    text-align: center;
}
.despre-card{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    max-width: 400px;
    text-align: center;
    row-gap: 12px;
}
.despre-card p{
    max-width: 200px;
}
.despre-card-list{
    display: flex;
    align-items: center;
    justify-content: center;
    column-gap: 160px;
    width: 100%;
}
.icon{
    font-size: 64px;
}
.despre-text{
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: center;
    column-gap: 10%;
}
.despre-img{
    max-width: 720px;
    width: 100%;
    height: 400px;
    border-radius: 10px;
    object-fit:cover;
}
.text{
    max-width: 50%;
    display: flex;
    flex-direction: column;
    row-gap: 12px;
    line-height: 20px;
}
.de-ce-noi{
    margin-top: 80px;
    display: flex;
    flex-direction: column;
    row-gap: 32px;
    align-items: center;
}
.de-ce-noi h2{
    text-align: center;
}
.de-ce-noi-text p{
    max-width: 50%;
}
.de-ce-noi-text{
    display: flex;
    flex-direction: row-reverse;
    column-gap: 32px;
}
.de-ce-noi-text p{
    line-height: 25px;
}

@media (max-width: 820px) {
    .despre-card-list{
        flex-direction: column;
        row-gap: 48px;
    }
    .despre-text{
        flex-direction: column-reverse;
        row-gap: 48px;
    }
    .text{
        max-width: 100%;
        align-items: center;
    }
    .text p{
        text-align: center;
    }
    .de-ce-noi{
        margin-top: 48px;
    }
}
@media (max-width: 1300px) {
    .de-ce-noi-text{
        flex-direction: column-reverse;
        align-items: center;
        row-gap: 24px;
    }
    .de-ce-noi-text p{
        max-width: 90%;
        text-align: center;
    }
}