.servicii-div{
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 80px;
}
.servicii-top{
    width: 100%;
    height: 230px;
    background-color: var(--orange);
}
.servicii-top h2{
    font-size: 36px;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
}
.servicii-list-div{
    margin: 80px 0px;
    display: flex;
    justify-content: space-between;
    column-gap: 24px;
}
.servicii-card{
    padding: 24px;
    box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.2);
    max-width: 350px;
    display: flex;
    flex-direction: column;
    align-items: center;
    row-gap: 24px;
    border-radius: 10px;
}
.servicii-card h2{
    border-bottom: 2px solid var(--orange);
}
.servicii-list{
    list-style: none;
    display: flex;
    flex-direction: column;
    row-gap: 24px;
    align-items: start;
    text-align: center;
    width: 100%;
}
.servicii-list li{
    display: flex;
    text-align: start;
    align-items: start;
    column-gap: 12px;

}
.orange{
    color: var(--orange);
    transform: translateY(2px);
    min-height: 14px;
    min-width: 14px;
}

@media (max-width:1024px) {
    .servicii-list-div{
        flex-direction: column;
        row-gap: 24px;
    }
}
@media (max-width:1440px) {
    .servicii-list-div{
        padding: 0px 12px;
        flex-direction: column;
    }
    .servicii-card{
        width: 100%;
        max-width: 100%;
    }
}