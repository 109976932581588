.phone-div{
    background-color: black; 
    width: 100%;
    display: flex;
    align-items: center;
    height: 50px;
    column-gap: 12px;
}
.phone-div a{
    cursor: pointer;
    color: white;
    display: flex;
    align-items: center;
    font-weight: 600;
    font-size: 14px;
    text-decoration: none;
}
.phone-div a:hover{
    color: var(--orange);
}
