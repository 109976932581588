@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');

:root {
    --orange: #1D9FC3
}

*{
    margin: 0px;
    padding: 0px;
    box-sizing: border-box;
    font-family: "Roboto", sans-serif;
    scroll-behavior: smooth;
}
body{
    scroll-behavior: smooth;
    overflow-x: hidden;
}
.App{
    /* overflow: hidden; */
}
::-webkit-scrollbar {
    width: 10px;
}
  
  ::-webkit-scrollbar-track {
    background: #f1f1f1;
}
  ::-webkit-scrollbar-thumb {
    background: var(--orange);
}
  ::-webkit-scrollbar-thumb:hover {
    background: rgba(0, 0, 0, 0.884) ;
    cursor: pointer;
}
.logo-main{
    max-width: 80px;
}
.padding{
    padding: 0px 10%;
}
.logo{
    cursor: pointer;
    user-select: none;
}
.flex{
    display: flex;
    align-items: center;
}
.current-page{
    font-weight: 700;
    color: var(--orange);
}
.nav{
    background-color: white;
    z-index: 2;
    position:sticky;
    top: 0px;
    height: 100px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid black;
}
h1{
    font-size: clamp(1.2rem, 2.5vw, 1.7rem); 
}
.menu-icon{
    scale: 1.5;
    cursor: pointer;
}
.menu-list{
    display: flex;
    align-items: center;
    justify-content: space-between;
    list-style: none;
    column-gap: 24px;
}
.menu-list li{
    display: inline-block;
    text-align: center;
    user-select: none;
}
.menu-list li::before{
    font-weight: 700;
}
.menu-list li:hover{
    cursor: pointer;
}
.contact-btn{
    transition: 0.3s;
    color: var(--orange);
    background-color: transparent;
    border: 1px solid var(--orange);
    padding: 16px 24px;
    border-radius: 5px;
    font-weight: 700;
    font-size: 16px;
    cursor: pointer;
}
.contact-btn:hover{
    color: white;
    border: 1px solid var(--orange);
    background-color: var(--orange);
    padding: 16px 24px;
    border-radius: 5px;
    font-weight: 700;
    font-size: 16px;
    cursor: pointer;
}
.mobile-menu{
    transition: 0.3s;
    background-color: white;
    position: absolute;
    width: 100%;
    left: 0px;
    bottom: 0px;
    transform: translateY(101%);
    overflow: hidden;
    z-index: 2;
}
.mobile-menu-open{
    height: 260px;
}
.mobile-menu-close{
    height: 0px;
}
.menu-list-mobile li{
    padding: 12px 24px;
}
.en-btn{
    background-image: url('./assets/uk-flag.png');
    border: 0px;
    width: 40px;
    height: 40px;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    color: transparent;
    cursor: pointer;
    background-color: transparent;
    
}
.ro-btn{
    background-image: url('./assets/ro-flag.png');
    border: 0px;
    width: 40px;
    height: 40px;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    color: transparent;
    cursor: pointer;
    background-color: transparent;
    
}
.lang-div{
    display: flex;
    align-items: center;
    column-gap: 12px;
}
.lang-div-mobile{
    display: flex;
    align-items: center;
    column-gap: 12px;
    padding: 0px 24px;
    justify-content: center;
}
.buttons-div{
    display: flex;
    column-gap: 24px;
}

@media (min-width:1025px) {
    .menu-icon{
        display: none;
    }
    .mobile-menu-open{
        display: none;
    }
    
}
@media (max-width:1024px) {
    .menu-list{
        display: none;
    }
    .contact-btn{
        display: none;
    }
    .padding{
        padding: 0px 24px;
    }
    .lang-div{
        display: none;
    }
    .logo-main{
        max-width: 50px;
    }
    .nav{
        height: 70px;
    }
}
@media (max-width:425px) {
    .padding{
        padding: 0px 12px;
    }
}
