.footer-div{
    padding-top: 80px;
    padding-bottom: 80px;
    background-color: black;
}
.footer-content{
    display: flex;
    justify-content: space-between;
}
.legal{
    color: white;
    display: flex;
    flex-direction: column;
    max-width: 320px;
    row-gap: 12px;
}
.linkuri-footer{
    color: white;
    list-style: none;
    display: flex;
    flex-direction: column;
    row-gap: 12px;
}
.linkuri-footer li:hover{
    cursor: pointer;
    color: var(--orange);
}
.linkuri-footer li{
    display: flex;
    align-items: center;
    column-gap: 4px;
    color: rgb(139, 129, 129);
    width: fit-content;
}
.linkuri-footer p{
    font-size: 24px;
}
.servicii-footer{
    color: white;
    list-style: none;
    display: flex;
    flex-direction: column;
    row-gap: 12px;
}
.servicii-footer li:hover{
    cursor: pointer;
    color: var(--orange);
}
.servicii-footer li{
    display: flex;
    align-items: center;
    column-gap: 4px;
    color: rgb(139, 129, 129);
    width: fit-content;
}
.servicii-footer p{
    font-size: 24px;
}
.contact-footer{
    color: white;
    list-style: none;
    display: flex;
    flex-direction: column;
    row-gap: 12px;
}
.contact-footer p:nth-child(1){
    font-size: 24px;
}
.anpc{
    background-image: url('../../assets/anpc-sal1-1.png');
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
    content: none;
    color: transparent;
    height: 100px;
}

.litigii{
    background-image: url('../../assets/anpc-sol.png');
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
    color: transparent;
    height: 100px;
}
.orange-dot{
    color: var(--orange);
}
.tel{
    cursor: pointer;
    color: white;
    display: flex;
    align-items: center;
    font-weight: 600;
    font-size: 18px;
    text-decoration: none;
    margin-left: 4px;
}
.tel:hover{
    color:var(--orange)
}
.scale{
    font-size: 24px;
}
.scale2{
    font-size: 20px;
}
.facebook{
    scale: 2;
    margin-top: 30px;
    transition: 0.3s;
    color: var(--orange);
}
.facebook:hover{
    cursor: pointer;
    scale: 2.4;
}

@media (max-width:768px ){
    .footer-content{
        flex-direction: column;
        row-gap: 24px;
    }
    .facebook{
        margin-top: 10px;
    }

}