.contact-div{
    padding-top: 80px;
    padding-bottom: 80px;
    display: flex;
    column-gap: 60px;
}
.contact-left{
    width: 50%;
    display: flex;
    flex-direction: column;
    row-gap: 32px;
}
.orange-filter{
    scale: 2;
    margin-right: 12px;
    color: var(--orange);
}
.contact-phone{
    cursor: pointer;
    color: var(--orange);
    display: flex;
    align-items: center;
    font-weight: 600;
    font-size: 18px;
    text-decoration: none;
    text-align: center;
    margin-left: 8px;
}
.contact-form{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    row-gap: 12px;
    width: 50%;
}
.form-inputs{
    width: 100%;
    display: flex;
    flex-direction: column;
    row-gap: 8px;
}
.form-inputs *{
    width: 100%;
    resize: none;
}
.mesaj-text{
    height: 190px;
}
input, textarea{
    padding: 8px 12px;
    border-radius: 5px;
    border: 1px solid gray;
    margin-top: 4px;
    font-size: 20px;
}
.contact-form > p{
    color: var(--orange);
}
.contact-form > h2{
    font-size: 40px;
    text-align: center;
}
.form-inputs p{
    font-weight: 700;
    margin-top: 4px;
}
.acord-div{
    display: flex;
    align-items: center;
    text-align: center;
    justify-content: center;
    column-gap: 8px;

}
.checkbox{
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid gray;
    width: 20px;
    height: 20px;
    cursor: pointer;
    border-radius: 5px;
}
.checkbox > *{
    color: var(--orange);
}


@media (max-width:1024px) {
    .contact-div{
        flex-direction: column;
    }
    .map{
        width: 100%;
    }
    .contact-left{
        width: 100%;
        align-items: center;
    }
    .flex{
        flex-direction: column;
    }
    .flex *{
        text-align: center;
        margin-top: 8px;
    }
    .contact-form{
        width: 100%;
        align-items: center;
        margin-top: 60px;
    }
}
@media (max-width:425px) {
    .form-inputs-div{
        flex-direction: column !important;
    }
}